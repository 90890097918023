<template>
    <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">POS FINAL QUANTITY</span>
                <button type="button" class="closeModal" aria-label="Close" @click="hideFinalQuantity()">
                    <span aria-hidden="true">
                        <i class="icon icon-close"></i>
                    </span>
                </button>
            </div>
            <div class="v-modal-body pt-0 pb-0">
                <div class="v-modal-layout d-form" v-if='product'>
                    <div class="m-6 mb-13">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="table-responsive px-4" style="overflow: hidden !important;border-right: 1px solid #4e5764;">
                                    <h5 style="color: #00448b;font-size: 14px;text-align: center;margin: 10px auto;font-weight: 600;">ADDITIONS</h5>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Starting Stock
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.starting_stock }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Stock In (From Suppliers)
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.stock_in }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Free of Charge (FOC)
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.free_of_charge }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Transfer In
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.transfer_in }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr style="">
                                                <th class="text-uppercase" style="text-transform: capitalize !important;color: #00448b;font-size: 13px !important;width: 70%;padding: 5px 5px !important;border-bottom: 1px solid #4e5764 !important;border-top: 1px solid #4e5764 !important;">
                                                    Total
                                                </th>
                                                <th class="text-uppercase" style="text-transform: capitalize !important;color: #00448b;font-size: 13px !important;width: 30%;padding: 5px 5px !important;border-bottom: 1px solid #4e5764 !important;border-top: 1px solid #4e5764 !important;"> 
                                                    {{ product.addition }}
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="table-responsive px-4" style="overflow: hidden !important;">
                                    <h5 style="color: #00448b;font-size: 14px;text-align: center;margin: 10px auto;font-weight: 600;">DEDUCTIONS</h5>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Quantity Sold
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.sold }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Transfer Out
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.transfer_out }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Wastage
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.wastage }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 40%;">
                                                        Void
                                                    </span>
                                                </td>
                                                <td style="padding: 3px 5px !important;">
                                                    <span class="text-bold" style="font-size: 12px;line-height: 1.22;font-weight: 600;color: #303031;width: 30%;">
                                                        {{ product.void }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr style="">
                                                <th class="text-uppercase" style="text-transform: capitalize !important;color: #00448b;font-size: 13px !important;width: 70%;padding: 5px 5px !important;border-bottom: 1px solid #4e5764 !important;border-top: 1px solid #4e5764 !important;">
                                                    Total
                                                </th>
                                                <th class="text-uppercase" style="text-transform: capitalize !important;color: #00448b;font-size: 13px !important;width: 30%;padding: 5px 5px !important;border-bottom: 1px solid #4e5764 !important;border-top: 1px solid #4e5764 !important;"> 
                                                    {{ product.deduction }}
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="border-radius bg-secondary pt-3 pb-3 mt-8">
                            <div class="row">
                                <div class="col-lg-3">
                                    <h6 class="font-weight-bold text-white text-center">ADDITIONS</h6>
                                    <h6 class="font-weight-bold text-white mt-2 text-center">{{ product.addition }}</h6>
                                </div>
                                <div class="col-lg-1">
                                    <h6 class="font-weight-bold text-white text-center pt-5">-</h6>
                                </div>
                                <div class="col-lg-3">
                                    <h6 class="font-weight-bold text-white text-center">DEDUCTIONS</h6>
                                    <h6 class="font-weight-bold text-white mt-2 text-center">{{ product.deduction }}</h6>
                                </div>
                                <div class="col-lg-1">
                                    <h6 class="font-weight-bold text-white text-center pt-5">=</h6>
                                </div>
                                <div class="col-lg-4">
                                    <h6 class="font-weight-bold text-white text-center">POS FINAL QUANTITY</h6>
                                    <h6 class="font-weight-bold text-white mt-2 text-center">{{ product.pos_final_quantity }}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name', 'product'],
    methods:{
        hideFinalQuantity() {
            this.$modal.hide(this.modal_name);
        }    
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
</style>